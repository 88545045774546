import React from "react"
import {Carousel, Form} from "react-bootstrap";
import CardLayout from "../components/CardLayout";

import {faLightbulb, faMoneyBillAlt} from "@fortawesome/free-regular-svg-icons";
import {faChartArea} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import PrimaryFooter from "../components/PrimaryFooter";
import PrimaryMenu from "../components/PrimaryMenu";
import {withTrans} from "../i18n/withTrans";

function CardsHolder({ children }) {
  return <div className={'home-overlapping-container'}>
    <div className={'home-cards-container'}>
      <div className={'container'}>
        <div className={'row'}>
          { children }
        </div>
      </div>
    </div>
    <div className={'home-cards-bottom-line'}/>
  </div>
}

function BlogPost({ title, author, date, children }) {
  return <div className={'col-lg-2 col-md-6'}>
    <div className={'my-1'}>
      <div><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcT9i-t0o_ltZf_c5ZQ4F4kbuETdhNDdxsjIYKaofkjTM3BmHTqc' alt={'Post'}/></div>
      <div className={'home-blog-post-title'}>Network in Business Security</div>
      <div className={'home-blog-post-meta'}>POSTED BY <b>ADMIN</b> <b>OCTOBER 13, 2020</b></div>
      <div className={'home-blog-post-excerpt'}>E-commerce sites have taken retail online and with such its audience. Once representatives used to be...</div>
    </div>
  </div>
}

// Σχολικός Επαγγελματικός Προσανατολισμός - με <b>Πραγματικές Καριέρες</b>

export function Home() {
  return <div>
    <div className={"container"}>
      <PrimaryMenu/>
    </div>

    <div className={'home-headline-container'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-sm'}>
            <div className={'home-headline-label'}>Making Your Business</div>
            <div className={'home-headline-label'}>Ideas <b>Come True</b></div>
            <div className={'home-headline-description'}>We provide businesses with all financial services including financial analysis of the enterprise.</div>
            <div><Button>Read More</Button></div>
          </div>
          <div className={'col-sm'}><img src={'/undraw_voice_interface_eckp.webp'} alt={'Filler'}/></div>
        </div>
      </div>
    </div>

    <CardsHolder>
      <CardLayout title={"Public Events"} icon={faLightbulb}>
        Το επόμενο σεμινάριο:<br/>
        Νέοι Εθελοντές
        @ 10:00 [EST] 10/10/10
      </CardLayout>
      <CardLayout title={"Past Events"} icon={faMoneyBillAlt}>
        -
      </CardLayout>
      <CardLayout title={"Get our newspaper"} icon={faChartArea}>
        <Form>
          <input/>
          <Button>Subscribe</Button>
        </Form>
      </CardLayout>
    </CardsHolder>

    <div className={'home-achievements-container'}>
      <div className={'container'}>
        <h4>Our Achievements</h4>
        <div className={'home-achievements-explanation'}>Business plan is a base for future business project which includes all aspects and makes a forecast about your project's payback.</div>
        <div className={'row home-achievements-metrics'}>
          <div className={'col-sm home-achievement-metrics-counter'}>
            <div><span className={'home-achievement-metrics-counter-number'}>22</span></div>
            <div><span className={'home-achievement-metrics-counter-title'}>Εθελοντές</span></div>
          </div>
          <div className={'col-sm home-achievement-metrics-counter'}>
            <div><span className={'home-achievement-metrics-counter-number'}>33</span></div>
            <div><span className={'home-achievement-metrics-counter-title'}>Ειδικότητες</span></div>
          </div>
          <div className={'col-sm home-achievement-metrics-counter'}>
            <div><span className={'home-achievement-metrics-counter-number'}>14</span></div>
            <div><span className={'home-achievement-metrics-counter-title'}>Τοποθεσίες Παγκοσμίως</span></div>
          </div>
          <div className={'col-sm home-achievement-metrics-counter'}>
            <div><span className={'home-achievement-metrics-counter-number'}>10</span></div>
            <div><span className={'home-achievement-metrics-counter-title'}>Παρουσιάσεις</span></div>
          </div>
        </div>
      </div>
    </div>

    <div className={'home-results-container'}>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-sm'}>
            <h2>Our Results</h2>
            <div className={'home-results-description'}>We are a team of like-minded people who do business, generate ideas and realize them. Finance, Marketing, Jurisprudence and Business Economy are our directions and we know how to deal with many financial issues.</div>
            <Button>READ MORE</Button>
          </div>
          <div className={'col-sm'}>[Chart]</div>
        </div>
      </div>
    </div>

    <div className={'home-get-started-container'}>
      <div className={'container'}>
        <div className={'home-get-started-title'}>Ενδιαφέρεσαι να συμμετάσχεις;</div>
        <div className={''}>We have over fifteen years of successful experience in financial sphere in the US.</div>
        <div className={'row'}>
          <Button>For Schools</Button>
          <Button>For Educators</Button>
          <Button>For Students</Button>
        </div>
      </div>
    </div>

    <div className={'home-blogs-container'}>
      <div className={'container my-3'}>
        <div className={'row mx-auto'}>
          <Carousel>
            <Carousel.Item active><BlogPost/></Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>

    <PrimaryFooter/>
  </div>
}

export default withTrans(Home);