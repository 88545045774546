/**
 * CardLayout.js - Card component.
 *
 * Note: This is using Font Awesome to display the icon at the top.
 *
 * @author Alex Symeonidis
 */
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CardLayout({ children, title, icon }) {
  return (
    <div className={"col-sm card-layout"}>
      <div className={"text-center card-layout-icon"}>
        <FontAwesomeIcon icon={icon}/>
      </div>
      <div className={"text-center card-layout-title"}>{title}</div>
      <div className={"text-center card-layout-details"}>
        {children}
      </div>
    </div>
  );
}

export default CardLayout;